import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFAQCategory,
  deleteFAQCategory,
  getFAQCategories,
  getFAQCategory,
  updateFAQCategory,
} from "../../../services/CategoryServices/categoryFAQServices";

const initialState = {
  loading: false,
  faq_categories: [],
  faq_category: {},
};

export const getAllFAQCategories = createAsyncThunk(
  "faq_categories/getAllFAQCategories",
  async () => {
    try {
      const { data } = await getFAQCategories();
      return data.categories.data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getFAQCategoryWithId = createAsyncThunk(
  "faq_categories/getFAQCategoryWithId",
  async (category_id) => {
    try {
      const { data } = await getFAQCategory(category_id);
      return data.faqCategory;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewFAQCategory = createAsyncThunk(
  "faq_categories/addNewFAQCategory",
  async (data) => {
    try {
      const { status } = await createFAQCategory(data);
      if (status >= 200) {
        window.location.replace("/faq-category");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeFAQCategory = createAsyncThunk(
  "faq_categories/removeFAQCategory",
  async (category_id) => {
    try {
      const { status } = await deleteFAQCategory(category_id);
      if (status >= 200) {
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateFAQCategoryById = createAsyncThunk(
  "post_categories/updateFAQCategoryById",
  async ({ category_id, data }) => {
    try {
      const { status } = await updateFAQCategory(category_id, data);
      if (status >= 200) {
        window.location.replace("/faq-category");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const faqCategorySlice = createSlice({
  name: "faq_category",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFAQCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFAQCategories.fulfilled, (state, { payload }) => {
        state.faq_categories = payload;
        state.loading = false;
      })
      .addCase(getFAQCategoryWithId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFAQCategoryWithId.fulfilled, (state, { payload }) => {
        state.faq_category = payload;
        state.loading = false;
      })
      .addCase(updateFAQCategoryById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFAQCategoryById.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default faqCategorySlice.reducer;
