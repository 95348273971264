import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  getUser,
  getUsers,
  restoreUser,
} from "../../../services/UsersServices/usersServices";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  users: [],
  user: {},
};

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async ({ page, search }) => {
    try {
      const { data } = await getUsers(page, search);
      return data.users;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (user_id) => {
    try {
      const { data } = await getUser(user_id);
      return data.user;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const suspendUser = createAsyncThunk(
  "users/suspendUser",
  async (user_id) => {
    try {
      const { status } = await deleteUser(user_id);
      if (status >= 200) {
        toast.success("کاربر با موفقیت تعلیق شد");
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const handleRestoreUser = createAsyncThunk(
  "users/handleRestoreUser",
  async (user_id) => {
    try {
      const { status } = await restoreUser(user_id);
      if (status >= 200) {
        toast.success("کاربر با موفقیت فعال شد");
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.loading = false;
      })
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.user = payload;
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
