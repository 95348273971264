import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { newsLetters } from "../../../services/NewsLetters/NewsLetters";

export const initialState = {
  loading: false,
  news_letters: [],
};

export const getAllNewsLetters = createAsyncThunk(
  "news/getAllNewsLetters",
  async () => {
    try {
      const { data } = await newsLetters();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllNewsLetters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNewsLetters.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.news_letters = payload;
      });
  },
});

export default settingSlice.reducer;
