import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createVillage,
  deleteVillage,
  getVillage,
  getVillages,
  updateVillage,
} from "../../../services/VillageServices/VillageServices";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

export const initialState = {
  loading: false,
  villages: [],
  village: {},
};

const Alert = withReactContent(Swal);

export const getAllVillages = createAsyncThunk(
  "villages/getAllVillages",
  async (page) => {
    try {
      const { data } = await getVillages(page);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getVillageById = createAsyncThunk(
  "villages/getVillageById",
  async (village_id) => {
    try {
      const { data } = await getVillage(village_id);
      return data.village;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewVillage = createAsyncThunk(
  "villages/addNewVillage",
  async (initialData) => {
    try {
      const { status } = await createVillage(initialData);
      if (status >= 200) {
        toast.success("روستا با موفقیت اضافه شد");
        window.location.replace("/villages");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateVillageById = createAsyncThunk(
  "villages/updateVillageById",
  async ({ village_id, initialData }) => {
    try {
      const { status } = await updateVillage(village_id, initialData);
      if (status >= 200) {
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const deleteVillageById = createAsyncThunk(
  "villages/deleteVillageById",
  async (village_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "روستا حذف شود؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteVillage(village_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const villagesSlice = createSlice({
  name: "villages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllVillages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVillages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.villages = payload;
      })
      .addCase(getVillageById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVillageById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.village = payload;
      })
      .addCase(addNewVillage.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewVillage.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default villagesSlice.reducer;
