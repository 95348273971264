import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFaq,
  deleteFaq,
  getFaq,
  getFaqs,
  updateFaq,
} from "../../../services/FaqServices/faqServices";

const initialState = {
  loading: false,
  faqs: [],
  faq_details: {},
};

export const getAllFAQs = createAsyncThunk("faq/getAllFAQs", async () => {
  try {
    const { data } = await getFaqs();
    return data.data;
  } catch (ex) {
    console.log(ex);
  }
});

export const getFAQById = createAsyncThunk("faq/getFAQById", async (faq_id) => {
  try {
    const { data } = await getFaq(faq_id);
    return data;
  } catch (ex) {
    console.log(ex);
  }
});

export const addNewFAQ = createAsyncThunk("faq/addNewFAQ", async (data) => {
  try {
    const { status } = await createFaq(data);
    if (status >= 200) {
      window.location.replace("/faqs");
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const removeFAQ = createAsyncThunk("faq/removeFAQ", async (faq_id) => {
  try {
    const { status } = await deleteFaq(faq_id);
    if (status >= 200) {
      window.location.reload();
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const updateFAQById = createAsyncThunk(
  "faq/updateFAQById",
  async ({ faq_id, data }) => {
    try {
      const { status } = await updateFaq(faq_id, data);
      if (status >= 200) {
        window.location.replace("/faqs");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFAQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFAQs.fulfilled, (state, { payload }) => {
        state.faqs = payload;
        state.loading = false;
      })
      .addCase(getFAQById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFAQById.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.faq_details = payload;
        state.loading = false;
      });
  },
});

export default faqSlice.reducer;
