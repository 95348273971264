import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { config } from "../../../config/config";
import {
  createActivity,
  deleteActivity,
  getActivities,
  getActivity,
  getActivityStoreInfo,
  updateActivity,
} from "../../../services/activitisServices/activitiesServices";

const Alert = withReactContent(Swal);

const initialState = {
  loading: false,
  activities: [],
  activity_details: {},
  activity_store_info: {},
};

export const getAllActivities = createAsyncThunk(
  "activities/getAllActivities",
  async () => {
    try {
      const { data } = await getActivities();
      return data.activities;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getActivityById = createAsyncThunk(
  "activities/getActivityById",
  async (activity_id) => {
    try {
      const { data } = await getActivity(activity_id);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewActivity = createAsyncThunk(
  "activities/addNewActivity",
  async (data) => {
    try {
      const { status } = await createActivity(data);
      if (status >= 200) {
        toast.success("فعالیت با موفقیت ایجاد شد");
        window.location.replace("/activities");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeActivity = createAsyncThunk(
  "activities/removeActivity",
  async (activity_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "فعالیت حذف شود ؟",
        text: "این عملیات بازگشت ناپذیر است آیا اطمینان دارید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteActivity(activity_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getActivityStore = createAsyncThunk(
  "activities/getActivityStore",
  async () => {
    try {
      const { data } = await getActivityStoreInfo();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateActivityById = createAsyncThunk(
  "activities/updateActivityById",
  async ({ activity_id, initilaData }) => {
    try {
      const { status } = await updateActivity(activity_id, initilaData);
      if (status >= 200) {
        toast.success("فعالیت با موفقیت ویرایش شد");
        window.location.replace("/activities");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        state.activities = payload;
        state.loading = false;
      })
      .addCase(addNewActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewActivity.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getActivityById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.activity_details = payload;
      })
      .addCase(getActivityStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.activity_store_info = payload;
      });
  },
});

export default activitySlice.reducer;
