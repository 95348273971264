import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNotification,
  deleteNotification,
  getNotifications,
} from "../../../services/NotificationServices/NotificationServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const initialState = {
  loading: false,
  notifications: [],
  notification: {},
};

const Alert = withReactContent(Swal);

export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async (page) => {
    try {
      const { data } = await getNotifications(page);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const setNewNotification = createAsyncThunk(
  "notifications/setNewNotification",
  async (initialData) => {
    try {
      const { status } = await createNotification(initialData);
      if (status >= 200) {
        window.location.replace("/notifications");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeNotification = createAsyncThunk(
  "notifications/removeNotification",
  async (notif_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "اعلان حذف شود ؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteNotification(notif_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.loading = false;
      })
      .addCase(setNewNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(setNewNotification.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default notificationSlice.reducer;
