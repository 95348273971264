import http from "../httpServices";

/**
 *
 * @returns list of all contacts
 */
export const getContacts = (page = 1, status, search = "") => {
  return http.get(
    `admin/contact?page=${page}&search=${search}${
      status !== 0 ? `&status=${status}` : ""
    }`
  );
};

/**
 *
 * @param {number} contact_id id of contact which you want to delete
 * @returns contact deleted
 */
export const deleteContact = (contact_id) => {
  return http.delete(`admin/contact/${contact_id}`);
};
