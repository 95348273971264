import http from "../httpServices";

/**
 *
 * @returns get all notifications
 */
export const getNotifications = (page = 1) => {
  return http.get(`admin/notification?page=${page}`);
};

/**
 *
 * @param {object} data object of title , text , status & user_id
 * @returns notification created
 */
export const createNotification = (data) => {
  return http.post(`admin/notification`, data);
};

/**
 *
 * @param {number} notif_id id of notification whcih want to delete
 * @returns notification deleted
 */
export const deleteNotification = (notif_id) => {
  return http.delete(`admin/notification/${notif_id}`);
};
