import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptPlaceComment,
  acceptPostComment,
  deletePlaceComment,
  deletePostComment,
  getComments,
} from "../../../services/CommentsServices/commentsServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const Alert = withReactContent(Swal);

const initialState = {
  loading: false,
  comments: [],
  comment: {},
};

export const getAllComments = createAsyncThunk(
  "comments/getAllComments",
  async (page) => {
    try {
      const { data } = await getComments(page);
      return data.comments;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const acceptComment = createAsyncThunk(
  "comments/acceptComment",
  async ({ is_place_comment, comment_id }) => {
    try {
      if (is_place_comment) {
        const { status } = await acceptPlaceComment(comment_id);
        if (status >= 200) {
          window.location.reload();
        }
      } else {
        const { status } = await acceptPostComment(comment_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeComment = createAsyncThunk(
  "comments/removeComment",
  async ({ is_place_comment, comment_id }) => {
    try {
      const { sweet_alert_basic } = config;
      const { isConfirmed } = await Alert.fire({
        title: "آیا مطمعن هستید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        if (is_place_comment) {
          const { status } = await deletePlaceComment(comment_id);
          if (status >= 200) {
            window.location.reload();
          }
        } else {
          const { status } = await deletePostComment(comment_id);
          if (status >= 200) {
            window.location.reload();
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllComments.fulfilled, (state, { payload }) => {
        state.comments = payload;
        state.loading = false;
      });
  },
});

export default commentSlice.reducer;
