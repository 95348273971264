import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPostCategory,
  deletePostCategory,
  getPostCategories,
  getPostCategory,
  updatePostCategory,
} from "../../../services/CategoryServices/categoryPostServices";

const initialState = {
  loading: false,
  post_categories: [],
  post_category: {},
};

export const getAllPostCategories = createAsyncThunk(
  "post_categories/getAllPostCategories",
  async ({page,search}) => {
    try {
      const { data } = await getPostCategories(page);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewPostCategory = createAsyncThunk(
  "post_categories/addNewPostCategory",
  async (data) => {
    try {
      const { status } = await createPostCategory(data);
      if (status >= 200) {
        window.location.replace("/post-category");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removePostCategory = createAsyncThunk(
  "post_categories/removePostCategory",
  async (category_id) => {
    try {
      const { status } = await deletePostCategory(category_id);
      if (status >= 200) {
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getPostCategoryWithId = createAsyncThunk(
  "post_categories/getPostCategoryWithId",
  async (category_id) => {
    try {
      const { data } = await getPostCategory(category_id);
      return data.category;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updatePostCategoryById = createAsyncThunk(
  "post_categories/updatePostCategoryById",
  async ({ category_id, data }) => {
    try {
      const { status } = await updatePostCategory(category_id, data);
      if (status >= 200) {
        window.location.replace("/post-category");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const postCategorySlice = createSlice({
  name: "postCategory",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPostCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPostCategories.fulfilled, (state, { payload }) => {
        state.post_categories = payload;
        state.loading = false;
      })
      .addCase(getPostCategoryWithId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostCategoryWithId.fulfilled, (state, { payload }) => {
        state.post_category = payload;
        state.loading = false;
      });
  },
});

export default postCategorySlice.reducer;
