import http from "../httpServices";

/**
 *
 * @returns array of difiiculties list
 */
export const getDifficulties = () => {
  return http.get("admin/difficulty");
};

/**
 *
 * @param {object} data object of name & index
 * @returns difficluty created
 */

export const createDifficulty = (data) => {
  return http.post(`admin/difficulty`, data);
};

/**
 *
 * @param {number} difficulty_id id of difficulty which want to update
 * @param {object} data object of name & index
 * @returns difficulty updated
 */

export const updateDifficulty = (difficulty_id, data) => {
  return http.put(`admin/difficulty/${difficulty_id}`, data);
};

/**
 *
 * @param {number} difficulty_id id of difficulty which want to delete
 * @returns difficulty deleted
 */

export const deleteDifficulty = (difficulty_id) => {
  return http.delete(`admin/difficulty/${difficulty_id}`);
};
