import http from "../httpServices";

/**
 *
 * @returns array of users list
 */

export const getUsers = (page = 1, serach = "") => {
  return http.get(`admin/users?page=${page}&search=${serach}`);
};

/**
 *
 * @param {number} user_id id of selected user which want to fetch details
 * @returns user details
 */

export const getUser = (user_id) => {
  return http.get(`admin/users/${user_id}`);
};

/**
 *
 * @param {number} user_id id of user which want to suspend
 * @returns user suspended
 */
export const deleteUser = (user_id) => {
  return http.delete(`admin/users/${user_id}`);
};

/**
 *
 * @param {number} user_id id of user which want to restore
 * @returns user restore successfuly
 */
export const restoreUser = (user_id) => {
  return http.get(`admin/users/${user_id}/restore`);
};
