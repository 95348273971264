import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { config } from "../../../config/config";
import {
  createTool,
  deleteTool,
  getTool,
  getTools,
  updateTool,
} from "../../../services/ToolsServices/toolsServices";

const initialState = {
  loading: false,
  tools: [],
  tool_details: {},
};

const Alert = withReactContent(Swal);

export const getAllTools = createAsyncThunk(
  "tools/getAllTools",
  async ({ page, search }) => {
    try {
      const { data } = await getTools(page, search);
      return data.tools;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getToolById = createAsyncThunk(
  "tools/getToolById",
  async (tool_id) => {
    try {
      const { data } = await getTool(tool_id);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewTool = createAsyncThunk("tools/addNewTool", async (data) => {
  try {
    const { status } = await createTool(data);
    if (status >= 200) {
      window.location.replace("/tools");
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const removeTool = createAsyncThunk(
  "tools/removeTool",
  async (tool_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "ابزار حذف شود؟",
        text: "این عملیات بازگشت ناپذیر است آیا اطمینان دارید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteTool(tool_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateToolWithId = createAsyncThunk(
  "tools/updateToolWithId",
  async ({ tool_id, data }) => {
    try {
      const { status } = await updateTool(tool_id, data);
      if (status >= 200) {
        window.location.replace("/tools");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const toolSlice = createSlice({
  name: "tools",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTools.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTools.fulfilled, (state, { payload }) => {
        state.tools = payload;
        state.loading = false;
      })
      .addCase(addNewTool.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewTool.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getToolById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getToolById.fulfilled, (state, { payload }) => {
        state.tool_details = payload;
        state.loading = false;
      })
      .addCase(updateToolWithId.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateToolWithId.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default toolSlice.reducer;
