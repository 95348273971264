import http from "../httpServices";

/**
 *
 * @returns array of types list
 */

export const getTypes = (page) => {
  return http.get(`admin/type?page=${page}`);
};

/**
 *
 * @param {number} type_id id of selected type which want to fetch details
 * @returns type details
 */

export const getType = (type_id) => {
  return http.get(`admin/type/${type_id}/edit`);
};

/**
 *
 * @param {object} data object of name , local_name & icon
 * @returns type created
 */

export const createType = (data) => {
  return http.post(`admin/type`, data);
};

/**
 *
 * @param {number} type_id id of selected type which want to update
 * @param {object} data object of name ,local_name & icon
 * @returns type updated
 */

export const updateType = (type_id, data) => {
  return http.post(`admin/type/${type_id}`, data);
};

/**
 *
 * @param {number} type_id id of selected type which want to delete
 * @returns type deleted
 */

export const deleteType = (type_id) => {
  return http.delete(`admin/type/${type_id}`);
};
