import http from "../httpServices";

/**
 *
 * @returns array of villages list
 */
export const getVillages = (page) => {
  return http.get(`admin/village?page=${page}`);
};

/**
 *
 * @param {number} village_id id of village which want to fetch details
 * @returns village details
 */
export const getVillage = (village_id) => {
  return http.get(`admin/village/${village_id}/edit`);
};

/**
 *
 * @param {object} data name , city_id , latitude & longitude
 * @returns village created
 */
export const createVillage = (data) => {
  return http.post("admin/village", data);
};

/**
 *
 * @param {number} village_id id of village which want to fetch village
 * @param {object} data name , city_id , latitude & longitude
 * @returns village updated
 */
export const updateVillage = (village_id, data) => {
  return http.put(`admin/village/${village_id}`, data);
};

/**
 *
 * @param {number} village_id id of village which want to delete
 * @returns village deleted
 */
export const deleteVillage = (village_id) => {
  return http.delete(`admin/village/${village_id}`);
};
