import http from "../httpServices";

/**
 *
 * @param {object} data object of mobile & password
 * @returns user log in
 */

export const login = (data) => {
  return http.post("v1/user/login", data);
};

/**
 * 
 * @returns user log out
 */

export const logout = () => {
  return http.post("v1/user/logout");
};
