import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteContact,
  getContacts,
} from "../../../services/ContactServices/ContactServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const initialState = {
  loading: false,
  messages: [],
};

const Alert = withReactContent(Swal);

export const getAllContacts = createAsyncThunk(
  "contacts/getAllContacts",
  async ({page,status,search}) => {
    try {
      const { data } = await getContacts(page,status,search);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeContact = createAsyncThunk(
  "contacts/removeContact",
  async (contact_id) => {
    const { sweet_alert_basic } = config;

    try {
      const { isConfirmed } = await Alert.fire({
        title: "پیام حذف شود؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteContact(contact_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const contactSlice = createSlice({
  name: "contacts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllContacts.fulfilled, (state, { payload }) => {
        state.messages = payload;
        state.loading = false;
      });
  },
});

export default contactSlice.reducer;
