import http from "../httpServices";

/**
 *
 * @returns array of params list
 */
export const getParams = () => {
  return http.get("admin/param");
};

/**
 *
 * @param {number} param_id id of param which want to fetch details
 * @returns param details
 */
export const getParam = (param_id) => {
  return http.get(`admin/param/${param_id}/edit`);
};

/**
 *
 * @param {object} data name , en_name & icon
 * @returns param created
 */
export const createParam = (data) => {
  return http.post("admin/param", data);
};

/**
 *
 * @param {number} param_id id of param which want to update
 * @param {object} data name , en_name & icon
 * @returns param updated
 */
export const updateParam = (param_id, data) => {
  return http.post(`admin/param/${param_id}`, data);
};

/**
 *
 * @param {number} param_id id of param which want to delete
 * @returns param deleted
 */
export const deleteParam = (param_id) => {
  return http.delete(`admin/param/${param_id}`);
};
