/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

axios.defaults.baseURL = "https://api.irangardi.app/api/";

const token = Cookies.get("iran_admin_token");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.interceptors.response.use(null, (err) => {
  const expectedError = err.response && err.response.status >= 500;

  if (expectedError) {
    toast.error("خطای از سمت سرور رخ داده است");
  }

  if (err.response && err.response.data) {
    const errorMessages = err.response.data.errors;
    const errors = Object.values(errorMessages);
    errors.map((item) => toast.error(item[0]));
  }

  return Promise.reject(err);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
