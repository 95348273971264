import http from "../httpServices";

/**
 *
 * @returns array of post categories list
 */

export const getPostCategories = (page) => {
  return http.get(`admin/post/category?page=${page}`);
};

/**
 *
 * @param {number} category_id id of category which want to fetch details
 * @returns category details
 */

export const getPostCategory = (category_id) => {
  return http.get(`admin/post/category/${category_id}/edit`);
};

/**
 *
 * @param {string} name category name
 * @returns category created
 */

export const createPostCategory = (name) => {
  return http.post(`admin/post/category?name=${name}`);
};

/**
 *
 * @param {number} category_id id of selected category which want to fetch details
 * @param {string} data category name
 * @returns category updated
 */

export const updatePostCategory = (category_id, data) => {
  return http.post(`admin/post/category/${category_id}`,data);
};

/**
 *
 * @param {number} category_id id of post category which want to delete
 * @returns category deleted
 */

export const deletePostCategory = (category_id) => {
  return http.delete(`admin/post/category/${category_id}`);
};
