import http from "../httpServices";

/**
 *
 * @returns array of posts list
 */

export const getPosts = (page,search="") => {
  return http.get(`admin/post?page=${page}&search=${search}`);
};

/**
 *
 * @param {number} post_id id of post which want to fetch details
 * @returns post details
 */

export const getPost = (post_id) => {
  return http.get(`admin/post/${post_id}/edit`);
};

/**
 *
 * @param {object} data object of titl, post, place_id & etc.
 * @returns post created
 */

export const createPost = (data) => {
  return http.post("admin/post", data);
};

/**
 *
 * @param {number} post_id id of selectd post which want to delete
 * @returns post deleted
 */

export const deletePost = (post_id) => {
  return http.delete(`admin/post/${post_id}`);
};

/**
 *
 * @param {number} post_id id of selectd post which want to update
 * @param {object} data object of titl, post, place_id & etc.
 * @returns post updated
 */

export const updatePost = (post_id, data) => {
  return http.post(`admin/post/${post_id}`, data);
};

/**
 *
 * @returns array of places list
 */

export const getPlacesForCreatePost = () => {
  return http.get("admin/post/create");
};
