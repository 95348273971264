import { Outlet } from "react-router-dom";
import SideBar from "../components/common/Sidebar/SideBar";
import Logout from "../components/common/Logout/Logout";

const MainLayout = () => {
  return (
    <div>
      <SideBar />
      <div className="app-content content">
        <Logout />
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
