import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { config } from "../../../config/config";
import {
  createTodo,
  deleteTodo,
  getTodo,
  getTodos,
  updateTodo,
} from "../../../services/TodosServices/todosServices";

const Alert = withReactContent(Swal);

const initialState = {
  loading: false,
  todos: [],
  todo_details: {},
};

export const getAllTodos = createAsyncThunk("todos/getAllTodos", async () => {
  try {
    const { data } = await getTodos();
    return data.todoList;
  } catch (ex) {
    console.log(ex);
  }
});

export const getTodoById = createAsyncThunk(
  "todos/getTodoById",
  async (todo_id) => {
    try {
      const { data } = await getTodo(todo_id);

      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewTodo = createAsyncThunk("todos/addNewTodo", async (data) => {
  try {
    const { status } = await createTodo(data);
    if (status >= 200) {
      toast.success("چک لیست با موفقیت ایجاد شد");
      setTimeout(() => {
        window.location.replace("/todos");
      }, 1500);
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const removeTodo = createAsyncThunk(
  "todos/removeTodo",
  async (todo_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "چک لیست حذف شود؟",
        text: "این عملیات بازگشت ناپذیر است آیا اطمینان دارید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteTodo(todo_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateTodoById = createAsyncThunk(
  "todos/updateTodoById",
  async ({ todo_id, data }) => {
    try {
      const { status } = await updateTodo(todo_id, data);
      if (status >= 200) {
        toast.success("چک لیست با موفقیت ویرایش شد");
        window.location.replace("/todos");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const todoSlice = createSlice({
  name: "todos",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTodos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTodos.fulfilled, (state, { payload }) => {
        state.todos = payload;
        state.loading = false;
      })
      .addCase(updateTodoById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTodoById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getTodoById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTodoById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.todo_details = payload;
      })
      .addCase(addNewTodo.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewTodo.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default todoSlice.reducer;
