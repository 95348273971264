import http from "../httpServices";

/**
 *
 * @returns array of places list
 */

export const getPlaces = (page, search = "", type) => {
  return http.get(
    `admin/place?page=${page}&search=${search}${type ? `&type=${type}` : ``}`
  );
};

/**
 *
 * @param {number} place_id id of place which want to fetch details
 * @returns place details
 */

export const getPlace = (place_id) => {
  return http.get(`admin/place/${place_id}/edit`);
};

/**
 *
 * @param {object} data object of name fa_titl , en_title & etc.
 * @returns place created
 */

export const createPlace = (data) => {
  return http.post("admin/place", data);
};

/**
 *
 * @returns list of all necessery information for create place such as types, activites & etc.
 */

export const placeStoreDetails = () => {
  return http.get("admin/place/create");
};

/**
 *
 * @param {number} place_id id of place which want to delete
 * @returns place delete
 */

export const deletePlace = (place_id) => {
  return http.delete(`admin/place/${place_id}`);
};

/**
 *
 * @param {number} place_id id of place which want to update
 * @param {object} data object of name fa_titl , en_title & etc.
 * @returns place updated
 */

export const updatePlace = (place_id, data) => {
  return http.post(`admin/place/${place_id}`, data);
};

/**
 *
 * @param {object} data array of types which want to get params
 * @returns type extra params
 */
export const getTypes = (data) => {
  return http.post(`admin/getTypes`, data);
};

/**
 *
 * @param {number} image_id id of image which want to delete
 * @returns image deleted
 */
export const deletePlaceImage = (image_id) => {
  return http.delete(`admin/place/deletePhoto/${image_id}`);
};
