import http from "../httpServices";

/**
 *
 * @returns array of tools list
 */

export const getTools = (page) => {
  return http.get(`admin/tool?page=${page}`);
};

export const getTool = (tool_id) => {
  return http.get(`admin/tool/${tool_id}/edit`);
};

/**
 *
 * @param {object} data object of name & icon
 * @returns
 */

export const createTool = (data) => {
  return http.post("admin/tool", data);
};

/**
 *
 * @param {number} tool_id id of tool which want to update
 * @param {object} data object of name & object
 * @returns tool update
 */

export const updateTool = (tool_id, data) => {
  return http.post(`admin/tool/${tool_id}`, data);
};

/**
 *
 * @param {number} tool_id id of tool which want to delete
 * @returns tool delete
 */

export const deleteTool = (tool_id) => {
  return http.delete(`admin/tool/${tool_id}`);
};
