import http from "../httpServices";

/**
 *
 * @returns array todos list
 */

export const getTodos = () => {
  return http.get("admin/todo");
};

/**
 *
 * @param {number} todo_id id of todo which want to fetch details
 * @returns todo details
 */

export const getTodo = (todo_id) => {
  return http.get(`admin/todo/${todo_id}/edit`);
};

/**
 *
 * @param {object} data object name,parent_id & icon
 * @returns todo created
 */

export const createTodo = (data) => {
  return http.post("admin/todo", data);
};

/**
 *
 * @param {number} todo_id id of selected todo which want to delete
 * @returns todo deleted
 */

export const deleteTodo = (todo_id) => {
  return http.delete(`admin/todo/${todo_id}`);
};

/**
 *
 * @param {number} todo_id id of selected todo which want to update
 * @param {object} data object of name & icon
 * @returns todo updated
 */

export const updateTodo = (todo_id, data) => {
  return http.post(`admin/todo/${todo_id}`, data);
};
