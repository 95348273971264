import http from "../httpServices";

/**
 *
 * @returns array of faq categories list
 */

export const getFAQCategories = () => {
  return http.get("admin/faq/category");
};

/**
 *
 * @param {number} category_id id of category which want to fetch details
 * @returns category details
 */

export const getFAQCategory = (category_id) => {
  return http.get(`admin/faq/category/${category_id}/edit`);
};

/**
 *
 * @param {string} name category name
 * @returns category created
 */

export const createFAQCategory = (name) => {
  return http.post(`admin/faq/category?name=${name}`);
};

/**
 *
 * @param {number} category_id id of selected category which want to fetch details
 * @param {string} data category name
 * @returns category updated
 */

export const updateFAQCategory = (category_id, data) => {
  return http.post(`admin/faq/category/${category_id}`, data);
};

/**
 *
 * @param {number} category_id id of faq category which want to delete
 * @returns category deleted
 */

export const deleteFAQCategory = (category_id) => {
  return http.delete(`admin/faq/category/${category_id}`);
};
