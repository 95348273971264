import http from "../httpServices";

/**
 *
 * @returns array of faqs list
 */

export const getFaqs = () => {
  return http.get("admin/faq");
};

/**
 *
 * @param {number} faq_id id of selected faq which want to fetch details
 * @returns faq deatils
 */

export const getFaq = (faq_id) => {
  return http.get(`admin/faq/${faq_id}/edit`);
};

/**
 *
 * @param {object} data object of faqCategori_id , question & anwser
 * @returns faq created
 */

export const createFaq = (data) => {
  return http.post("admin/faq", data);
};

/**
 *
 * @param {number} faq_id id of faq which want to update
 * @param {object} data object of faqCategori_id , question & anwser
 * @returns
 */

export const updateFaq = (faq_id, data) => {
  return http.post(`admin/faq/${faq_id}`, data);
};

/**
 *
 * @param {number} faq_id id of faq which want to delete
 * @returns faq deleted
 */

export const deleteFaq = (faq_id) => {
  return http.delete(`admin/faq/${faq_id}`);
};
