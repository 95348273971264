import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../../app/features/authSlice/authSlice";

const Logout = () => {
  const dispatch = useDispatch();

  return (
    <div className="px-3 d-flex justify-content-end pt-1">
      <i
        className="bx bx-log-out cursor-pointer"
        onClick={() => dispatch(logoutAdmin())}
      ></i>
    </div>
  );
};

export default Logout;
