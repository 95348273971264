/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link } from "react-router-dom";

const SidebarMultiItems = ({ title, menu_icon, items }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <li
      className={`nav-item has-sub mb-1 ${
        menuIsOpen ? "sidebar-group-active open" : " "
      }`}
      onClick={() => setMenuIsOpen((prevState) => !prevState)}
    >
      <a href="#">
        <i className={`${menu_icon} `}></i>
        <span className="menu-title">{title}</span>
      </a>
      <ul className="menu-content">
        {items.map((item, index) => (
          <li className={item.isActive ? "active" : " "} key={index}>
            <Link to={item.link}>
              <i className="bx bx-left-arrow-alt"></i>
              <span className="menu-item">{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default SidebarMultiItems;
