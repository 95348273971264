import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ****** Services Methods ****** //
import {
  createCity,
  getCities,
  getCity,
  updateCity,
} from "../../../services/CityServices/CityServices";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  cities: [],
  city: {},
};

export const getAllCities = createAsyncThunk(
  "cities/getAllCities",
  async (page) => {
    try {
      const { data } = await getCities(page);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getCityById = createAsyncThunk(
  "cities/getCityById",
  async (city_id) => {
    try {
      const { data } = await getCity(city_id);
      return data.city;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewCity = createAsyncThunk(
  "cities/addNewCity",
  async (initialData) => {
    try {
      const { status } = await createCity(initialData);
      if (status >= 200) {
        toast.success("شهر با موفقیت اضافه شده");
        window.location.reload()
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateCityById = createAsyncThunk(
  "cities/updateCityById",
  async ({ city_id, initialData }) => {
    try {
      const { status } = await updateCity(city_id, initialData);
      if (status >= 200) {
        toast.success("شهر با موفقیت ویرایش شده");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const deleteCityById = createAsyncThunk(
  "cities/deleteCityById",
  async ({ city_id, initialData }) => {
    try {
      const { status } = await updateCity(city_id, initialData);
      if (status >= 200) {
        toast.success("شهر با موفقیت ویرایش شده");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllCities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCities.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cities = payload;
      })
      .addCase(getCityById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCityById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.city = payload;
      })
      .addCase(addNewCity.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewCity.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default citiesSlice.reducer;
