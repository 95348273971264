import http from "../httpServices";

/**
 *
 * @returns array of comments list
 */
export const getComments = (page=1) => {
  return http.get(`admin/comments?page=${page}`);
};

/**
 *
 * @param {number} post_id id of post which want accept
 * @returns post comment accepted
 */
export const acceptPostComment = (post_id) => {
  return http.get(`admin/comments/post/${post_id}`);
};

/**
 *
 * @param {number} place_id id of place which want to accept
 * @returns place comment accepted
 */
export const acceptPlaceComment = (place_id) => {
  return http.get(`admin/comments/place/${place_id}`);
};

/**
 *
 * @param {number} post_id id of post which want to delete
 * @returns post comment deleted
 */
export const deletePostComment = (post_id) => {
  return http.delete(`admin/comments/post/delete/${post_id}`);
};

/**
 *
 * @param {number} place_id id of place which want to delete
 * @returns place comment delete
 */
export const deletePlaceComment = (place_id) => {
  return http.delete(`admin/comments/place/delete/${place_id}`);
};
