import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ****** Services ****** //
import {
  createParam,
  deleteParam,
  getParam,
  getParams,
  updateParam,
} from "../../../services/paramServices/paramServices";
import { toast } from "react-toastify";
// ***** Sweet alert 2 ****** //
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

export const initialState = {
  loading: false,
  params: [],
  param: {},
};

const Alert = withReactContent(Swal);

export const getAllParams = createAsyncThunk(
  "params/getAllParams",
  async () => {
    try {
      const { data } = await getParams();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getParamById = createAsyncThunk(
  "params/getParamById",
  async (param_id) => {
    try {
      const { data } = await getParam(param_id);
      return data.param;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewParam = createAsyncThunk(
  "params/addNewParam",
  async (initialData) => {
    try {
      const { status } = await createParam(initialData);
      if (status >= 200) {
        toast.success("ویژگی با موفقیت اضافه شد");
        window.location.replace("/params");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const deleteParamById = createAsyncThunk(
  "params/deleteParamById",
  async (param_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "ویژگی حذف شود ؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteParam(param_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateParamById = createAsyncThunk(
  "params/updateParamById",
  async ({ param_id, initialData }) => {
    try {
      const { status } = await updateParam(param_id, initialData);
      if (status >= 200) {
        toast.success("ویرایش موفقیت آمیز بود");
        // window.location.replace("/params");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const paramSlice = createSlice({
  name: "params",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllParams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllParams.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.params = payload;
      })
      .addCase(getParamById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParamById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.param = payload;
      })
      .addCase(addNewParam.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewParam.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateParamById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateParamById.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default paramSlice.reducer;
