import http from "../httpServices";

/**
 *
 * @returns array of activities list
 */

export const getActivities = () => {
  return http.get("admin/activity");
};

/**
 *
 * @param {number} activity_id id of activity id which want to fetch details
 * @returns activity details
 */

export const getActivity = (activity_id) => {
  return http.get(`admin/activity/${activity_id}/edit`);
};

/**
 * 
 * @returns information for create activity
 */
export const getActivityStoreInfo = () => {
  return http.get("admin/activity/create");
};

/**
 *
 * @param {object} data object of name, collection, tools & etc.
 * @returns activity created
 */

export const createActivity = (data) => {
  return http.post("admin/activity", data);
};

/**
 *
 * @param {number} activity_id id of activity which want to update
 * @returns
 */

export const deleteActivity = (activity_id) => {
  return http.delete(`admin/activity/${activity_id}`);
};

/**
 *
 * @param {number} activity_id id of selecte activity which want to update
 * @param {object} data object of name , collection, tools & etc
 * @returns
 */

export const updateActivity = (activity_id, data) => {
  return http.post(`admin/activity/${activity_id}`, data);
};
