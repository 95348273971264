import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createSlider,
  deleteSlider,
  getSlider,
  getSliders,
  updateSlider,
} from "../../../services/SliderServices/SliderServices";
// ****** Sweet alert2 ****** //
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const initialState = {
  loading: false,
  update_loading: false,
  sliders: [],
  slider: {},
};

const Alert = withReactContent(Swal);

export const getAllSliders = createAsyncThunk(
  "sliders/getAllSliders",
  async () => {
    try {
      const { data } = await getSliders();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getSliderById = createAsyncThunk(
  "sliders/getSliderById",
  async (slider_id) => {
    try {
      const { data } = await getSlider(slider_id);
      return data.slider;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewSlider = createAsyncThunk(
  "sliders/addNewSlider",
  async (initialData) => {
    try {
      const { status } = await createSlider(initialData);
      if (status >= 200) {
        window.location.replace("/sliders");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeSlider = createAsyncThunk(
  "sliders/removeSlider",
  async (slider_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "اسلادیر حذف شود؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteSlider(slider_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateSliderById = createAsyncThunk(
  "sliders/updateSliderById",
  async ({ slider_id, initialData }) => {
    try {
      const { status } = await updateSlider(slider_id, initialData);
      if (status >= 200) {
        window.location.replace("/sliders");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const sliderSlice = createSlice({
  name: "sliders",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSliders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSliders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sliders = payload;
      })
      .addCase(getSliderById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSliderById.fulfilled, (state, { payload }) => {
        state.slider = payload;
        console.log(payload);
        state.loading = false;
      })
      .addCase(addNewSlider.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewSlider.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateSliderById.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(updateSliderById.fulfilled, (state) => {
        state.update_loading = false;
      });
  },
});

export default sliderSlice.reducer;
