import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// Place services
import {
  createPlace,
  deletePlace,
  deletePlaceImage,
  getPlace,
  getPlaces,
  getTypes,
  placeStoreDetails,
  updatePlace,
} from "../../../services/PlaceServices/placeServices";

// SweetAlert2
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const placeAdaptor = createEntityAdapter({});

const Alert = withReactContent(Swal);

const initialState = placeAdaptor.getInitialState({
  loading: false,
  update_loading: false,
  error: null,
  types: [],
  activities: [],
  cities: [],
  places: [],
  provinces: [],
  features: [],
  place_details: {},
});

export const getAllPlaces = createAsyncThunk(
  "places/getAllPlaces",
  async ({ page, search, type }) => {
    try {
      const { data } = await getPlaces(page, search, type);
      return data.places;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getPlaceById = createAsyncThunk(
  "places/getPlaceById",
  async (placeId) => {
    try {
      const { data } = await getPlace(placeId);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updatePlaceById = createAsyncThunk(
  "places/updatePlaceById",
  async ({ place_id, initialPlace }) => {
    try {
      const { status } = await updatePlace(place_id, initialPlace);
      if (status >= 200) {
        toast.success("جاذبه گردشگری ویرایش شد");
        // window.location.replace("/places");
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response.status > 400) {
        toast.error("مشکلی رخ داده است مجددا تلاش فرمایید");
      }
    }
  }
);

export const addNewPlace = createAsyncThunk(
  "places/addNewPlace",
  async (data) => {
    try {
      const { status } = await createPlace(data);
      if (status >= 200) {
        toast.success("جاذبه گردشگری با موفقیت ایجاد شد");
        window.location.replace("/places");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getPlaceStoreDetails = createAsyncThunk(
  "places/getPlaceStoreDetails",
  async () => {
    try {
      const { data } = await placeStoreDetails();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getTypeFeatures = createAsyncThunk(
  "places/getTypeFeatures",
  async (initialData) => {
    try {
      const { data } = await getTypes(initialData);
      return data.types;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removePlace = createAsyncThunk(
  "places/removePlace",
  async (place_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "جاذبه گردشگری حذف شود؟",
        text: "این عملیات بازگشت ناپذیر است آیا اطمینان دارید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deletePlace(place_id);
        toast.success("مکان با موفقیت حذف شد");
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removePlaceImage = createAsyncThunk(
  "palces/removePlaceImage",
  async (image_id) => {
    try {
      const { status } = await deletePlaceImage(image_id);
      if (status >= 200) {
        toast.success("تصویر با موفقیت حذف شد ");
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const placeSlice = createSlice({
  name: "place",
  initialState,
  reducers: {
    placeTypes(state, { payload }) {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlaces.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.places = payload;
      })
      .addCase(addNewPlace.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewPlace.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addNewPlace.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPlaceStoreDetails.fulfilled, (state, { payload }) => {
        const loadedType = payload.types.map((type) => {
          return { value: type.id, label: type.name };
        });
        const loadedActivites = payload.activities.map((activity) => {
          return { value: activity.id, label: activity.name };
        });
        const loadedCity = payload.cities.map((city) => {
          return { value: city.id, label: city.name };
        });
        const loadedProvince = payload.provinces.map((city) => {
          return { value: city.id, label: city.name };
        });

        state.types = loadedType;
        state.activities = loadedActivites;
        state.cities = loadedCity;
        state.provinces = loadedProvince;
      })
      .addCase(getPlaceById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlaceById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.place_details = payload;
      })
      .addCase(updatePlaceById.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(updatePlaceById.fulfilled, (state) => {
        state.update_loading = false;
      })
      .addCase(getTypeFeatures.fulfilled, (state, { payload }) => {
        state.features = payload;
      });
  },
});

export const { selectAll, selectById, selectIds } = placeAdaptor.getSelectors(
  (state) => state.places
);

export const { placeTypes } = placeSlice.actions;

export default placeSlice.reducer;
