import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { config } from "../../../config/config";
import {
  createPost,
  deletePost,
  getPlacesForCreatePost,
  getPost,
  getPosts,
  updatePost,
} from "../../../services/PostServices/postServices";

const Alert = withReactContent(Swal);

const initialState = {
  loading: false,
  post_details_loading: false,
  post_palces: [],
  posts: [],
  post_details: {},
  error: null,
};

export const getAllPosts = createAsyncThunk(
  "posts/getAllPosts",
  async ({ page, search }) => {
    try {
      const { data } = await getPosts(page, search);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getPostById = createAsyncThunk(
  "posts/getPostById",
  async (post_id) => {
    try {
      const { data } = await getPost(post_id);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewPost = createAsyncThunk("posts/addNewPost", async (data) => {
  try {
    const { status } = await createPost(data);
    if (status >= 200) {
      window.location.replace("/posts");
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const getAllPlacesWithoutPagination = createAsyncThunk(
  "posts/getAllPlacesWithoutPagination",
  async () => {
    try {
      const { data } = await getPlacesForCreatePost();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removePost = createAsyncThunk(
  "posts/removePost",
  async (post_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "مقاله حذف شود؟",
        text: "این عملیات بازگشت ناپذیر است آیا اطمینان دارید؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deletePost(post_id);
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updatePostById = createAsyncThunk(
  "posts/removePost",
  async ({ post_id, data }) => {
    try {
      const { status } = await updatePost(post_id, data);
      if (status >= 200) {
        window.location.replace("/posts");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const postSlice = createSlice({
  name: "post",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, { payload }) => {
        state.posts = payload;
        state.loading = false;
      })
      .addCase(getPostById.pending, (state) => {
        state.loading = true;
        state.post_details_loading = true;
      })
      .addCase(getPostById.fulfilled, (state, { payload }) => {
        state.post_details = payload;
        state.loading = false;
        state.post_details_loading = false;
      })
      .addCase(
        getAllPlacesWithoutPagination.fulfilled,
        (state, { payload }) => {
          state.post_palces = payload;
        }
      )
      .addCase(updatePostById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePostById.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default postSlice.reducer;
