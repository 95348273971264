import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import { store } from "./app/store";
//styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-quill/dist/quill.snow.css";
import 'perfect-scrollbar/css/perfect-scrollbar.css';


const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer
      closeOnClick={true}
      theme="colored"
      position="top-right"
      pauseOnHover={false}
    />
  </BrowserRouter>
);
