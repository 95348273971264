import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// ! ----- Layouts ----- ! //
import MainLayout from "./layouts/MainLayout";
import ProtectedRoutes from "./utils/ProtectedRoutes";

const AuthLayout = lazy(() => import("./layouts/AuthLayout"));

const Loading = lazy(() => import("./utils/Loading"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

// ! ----- Post Categories ----- ! //
const PostCategoriesPage = lazy(() =>
  import("./pages/PostCategories/PostCategoriesPage")
);
const AddNewPostCategory = lazy(() =>
  import("./components/PostCategories/AddNewPostCategory")
);
const UpdatePostCategory = lazy(() =>
  import("./components/PostCategories/UpdatePostCategory")
);
// ! ----- FAQ Categories ----- ! //
const AddNewFAQCategory = lazy(() =>
  import("./components/FAQCategories/AddNewFAQCategory")
);
const UpdateFAQCategory = lazy(() =>
  import("./components/FAQCategories/UpdateFAQCategory.jsx")
);
// ! ----- FAQs ----- ! //
const FAQs = lazy(() => import("./pages/FAQs/FAQPage"));
const AddNewFAQ = lazy(() => import("./components/FAQs/AddNewFAQ"));
const UpdateFAQ = lazy(() => import("./components/FAQs/UpdateFAQ"));
const FAQCategoriesPage = lazy(() =>
  import("./pages/FAQCategories/FAQCategoriesPage")
);
// ! ----- Types ----- ! //
const Types = lazy(() => import("./pages/Types/TypesPage"));
const AddNewType = lazy(() => import("./components/Types/AddNewType"));
const UpdateType = lazy(() => import("./components/Types/UpdateType"));
// ! ----- Tools ----- ! //
const ToolsPage = lazy(() => import("./pages/Tools/ToolsPage"));
const AddNewTool = lazy(() => import("./components/Tools/AddNewTool"));
const UpdateTool = lazy(() => import("./components/Tools/UpdateTool"));
// ! ----- Activities ----- ! //
const ActivityPage = lazy(() => import("./pages/Activity/ActivityPage"));
const AddNewActivity = lazy(() =>
  import("./components/Activity/AddNewActivity")
);
const ActivityDetailsPage = lazy(() =>
  import("./pages/Activity/ActivityDetailsPage")
);
const UpdateActivity = lazy(() =>
  import("./components/Activity/UpdateActivity")
);
const UpdateSubActivity = lazy(() =>
  import("./components/Activity/UpdateSubActivity")
);
const AddNewSubActivity = lazy(() => import("./components/Activity/AddNewSubActivity.jsx"))
// ! ----- Todos ----- ! //
const TodosPage = lazy(() => import("./pages/Todos/TodosPage"));
const TodoDetailsPage = lazy(() => import("./pages/Todos/TodoDetailsPage"));
const AddNewTodo = lazy(() => import("./components/Todos/AddNewTodo"));
const UpdateTodo = lazy(() => import("./components/Todos/UpdateTodo"));
const UpdateSubTodo = lazy(() => import("./components/Todos/UpdateSubTodo"));
// ! ----- Posts ----- ! //
const UpdatePost = lazy(() => import("./components/Posts/UpdatePost"));
const PostsPage = lazy(() => import("./pages/Posts/PostsPage"));
const AddNewPost = lazy(() => import("./components/Posts/AddNewPost"));
// ! ----- Places ----- ! //
const PlacesPage = lazy(() => import("./pages/Places/PlacesPage"));
const AddNewPlace = lazy(() => import("./components/Places/AddNewPlace"));
const UpdatePlace = lazy(() => import("./components/Places/UpdatePlace"));
// ! ----- Auth ----- ! //
const Login = lazy(() => import("./pages/Login/Login"));
// ! ----- Users ----- ! //
const UsersPage = lazy(() => import("./pages/Users/UsersPage"));
const UserDetails = lazy(() => import("./components/Users/UserDetails.jsx"));
// ! ----- Comments ----- ! //
const PlaceComments = lazy(() => import("./components/Comments/PlaceComments"));
const PostComments = lazy(() => import("./components/Comments/PostComments"));

// ! ----- Sliders ----- ! //
const AddNewSlider = lazy(() => import("./components/Sliders/AddNewSlider"));
const Sliders = lazy(() => import("./components/Sliders/Sliders"));
const UpdateSlider = lazy(() => import("./components/Sliders/UpdateSlider"));

// ! ------- Notifications ------ ! //
const Notifications = lazy(() =>
  import("./components/Notifications/Notifications")
);
const AddNewNotification = lazy(() =>
  import("./components/Notifications/AddNewNotification")
);

// ! ------- Contact Us -------- ! //
const Contacts = lazy(() => import("./components/Contacts/Contacts"));

// ! ------- Place Cities -------- ! //
const PlaceCities = lazy(() =>
  import("./components/PlaceCities/PlaceCities.jsx")
);
const PlaceCityDetails = lazy(() =>
  import("./components/PlaceCities/PlaceCityDetails.jsx")
);

// ! ------- Place Villages -------- ! //
const PlaceVillages = lazy(() =>
  import("./components/PlaceVillages/PlaceVillages.jsx")
);
const AddNewPlaceVillage = lazy(() =>
  import("./components/PlaceVillages/AddNewPlaceVillage.jsx")
);
const PlaceVillageDetails = lazy(() =>
  import("./components/PlaceVillages/PlaceVillageDetails.jsx")
);

// ! ----------------- Type Params  ----------------- ! //
const TypeParams = lazy(() => import("./components/TypeParams/TypeParams.jsx"));
const AddNewPlaceParam = lazy(() =>
  import("./components/TypeParams/AddNewPlaceParam.jsx")
);
const UpdateTypeParam = lazy(() =>
  import("./components/TypeParams/UpdateTypeParam.jsx")
);

// ! ----------------- Type Params  ----------------- ! //
const NewsLetters = lazy(() =>
  import("./components/NewsLetters/NewsLetters.jsx")
);

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/post-category" element={<PostCategoriesPage />} />
            <Route path="/post-category/:id" element={<UpdatePostCategory />} />
            <Route path="/faq-category" element={<FAQCategoriesPage />} />
            <Route path="/faq-category/:id" element={<UpdateFAQCategory />} />
            <Route path="/add-faq-category" element={<AddNewFAQCategory />} />
            <Route path="/add-post-category" element={<AddNewPostCategory />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/faqs/:id" element={<UpdateFAQ />} />
            <Route path="/add-faq" element={<AddNewFAQ />} />
            <Route path="/types" element={<Types />} />
            <Route path="/types/:id" element={<UpdateType />} />
            <Route path="/add-type" element={<AddNewType />} />
            <Route path="/tools" element={<ToolsPage />} />
            <Route path="/tools/:id" element={<UpdateTool />} />
            <Route path="/add-tool" element={<AddNewTool />} />
            <Route path="/activities" element={<ActivityPage />} />
            <Route path="/activities/:id" element={<ActivityDetailsPage />} />
            <Route path="/add-activity" element={<AddNewActivity />} />
            <Route path="/add-sub-activity/:id" element={<AddNewSubActivity />} />
            <Route path="/update_activity/:id" element={<UpdateActivity />} />
            <Route
              path="/update_sub_activity/:id"
              element={<UpdateSubActivity />}
            />
            <Route path="/todos" element={<TodosPage />} />
            <Route path="/todos/:id" element={<TodoDetailsPage />} />
            <Route path="/update_todo/:id" element={<UpdateTodo />} />
            <Route path="/update_sub_todo/:id" element={<UpdateSubTodo />} />
            <Route path="/add-todo" element={<AddNewTodo />} />
            <Route path="/posts" element={<PostsPage />} />
            <Route path="/add-post" element={<AddNewPost />} />
            <Route path="/places" element={<PlacesPage />} />
            <Route path="/places/:id" element={<UpdatePlace />} />
            <Route path="/add-place" element={<AddNewPlace />} />
            <Route path="/posts/:id" element={<UpdatePost />} />

            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/:id" element={<UserDetails />} />

            <Route path="/place-comments" element={<PlaceComments />} />
            <Route path="/post-comments" element={<PostComments />} />

            <Route path="/add-slider" element={<AddNewSlider />} />
            <Route path="/sliders" element={<Sliders />} />
            <Route path="/sliders/:id" element={<UpdateSlider />} />

            <Route path="/notifications" element={<Notifications />} />
            <Route path="/add-notif" element={<AddNewNotification />} />

            <Route path="/contacts" element={<Contacts />} />
            <Route path="/letters" element={<NewsLetters />} />

            <Route path="/cities" element={<PlaceCities />} />
            <Route path="/cities/:id" element={<PlaceCityDetails />} />

            <Route path="/villages" element={<PlaceVillages />} />
            <Route path="/villages/:id" element={<PlaceVillageDetails />} />
            <Route path="/add-village" element={<AddNewPlaceVillage />} />

            <Route path="/params" element={<TypeParams />} />
            <Route path="/params/:id" element={<UpdateTypeParam />} />
            <Route path="/add-param" element={<AddNewPlaceParam />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
