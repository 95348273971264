/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

import SidebarMultiItems from "./SidebarMultiItems";
import { useRef } from "react";
import usePerfectScrollabar from "../../../hooks/usePerfectScrollabar";

const SideBar = () => {
  const sidebarRef = useRef(null);

  usePerfectScrollabar(sidebarRef);

  return (
    <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded">
      <div className="navbar-header expanded">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href="/">
              <div className="brand-logo">
                <img
                  className="logo"
                  alt="logo"
                  src={`/assets/images/logo/logo-header.png`}
                />
              </div>
              {/* <h2 className="brand-text mb-0">ایرانگردی</h2> */}
            </a>
          </li>
        </ul>
      </div>
      <div ref={sidebarRef} className="main-menu-content ps ps--active-y">
        <ul
          className="navigation navigation-main position-relative"
          id="navigation-main"
        >
          <li className="nav-item mb-1">
            <Link to={"/"}>
              <i className="bx bx-home-alt"></i>
              <span className="menu-title">داشبورد</span>
            </Link>
          </li>

          <SidebarMultiItems
            title="کاربران"
            menu_icon="bx bx-group"
            items={[{ title: "لیست کاربران", isActive: false, link: "/users" }]}
          />
          <SidebarMultiItems
            title="جاذبه های گردشگری"
            menu_icon="bx bx-group"
            items={[
              { title: "لیست جاذبه ها ", isActive: false, link: "/places" },
              { title: "دسته بندی جاذبه ها", isActive: false, link: "/types" },
              { title: "ویژگی ها", isActive: false, link: "/params" },
            ]}
          />
          <SidebarMultiItems
            title="بنرها "
            menu_icon="bx bx-notepad"
            items={[
              { title: "لیست بنرها", isActive: false, link: "/sliders" },
              { title: "افزودن بنر", isActive: false, link: "/add-slider" },
            ]}
          />

          <SidebarMultiItems
            title="فعالیت ها "
            menu_icon="bx bx-notepad"
            items={[
              { title: "لیست فعالیت ها", isActive: false, link: "/activities" },
              {
                title: "افزودن فعالیت",
                isActive: false,
                link: "/add-activity",
              },
            ]}
          />
          <SidebarMultiItems
            title="کوله پشتی "
            menu_icon="bx bx-notepad"
            items={[
              {
                title: "لیست چک لیست ها",
                isActive: false,
                link: "/todos",
              },
            ]}
          />
          <SidebarMultiItems
            title="ابزار ها"
            menu_icon="bx bx-notepad"
            items={[
              {
                title: "لیست ابزار ها",
                isActive: false,
                link: "/tools",
              },
              {
                title: "افزودن ابزار ",
                isActive: false,
                link: "/add-tool",
              },
            ]}
          />

          <SidebarMultiItems
            title="مقالات"
            menu_icon="bx bx-notepad"
            items={[
              {
                title: "لیست مقالات",
                isActive: false,
                link: "/posts",
              },
              {
                title: "دسته بندی  مقالات",
                isActive: false,
                link: "/post-category",
              },
            ]}
          />

          <SidebarMultiItems
            title="سوالات متداول"
            menu_icon="bx bx-notepad"
            items={[
              {
                title: "لیست سوالات",
                isActive: false,
                link: "/faqs",
              },
              {
                title: "دسته بندی  سوالات",
                isActive: false,
                link: "/faq-category",
              },
            ]}
          />

          <SidebarMultiItems
            title="کامنت ها"
            menu_icon="bx bx-notepad"
            items={[
              {
                title: "مقالات",
                isActive: false,
                link: "/post-comments",
              },
              {
                title: "جاذبه های گردشگری",
                isActive: false,
                link: "/place-comments",
              },
            ]}
          />
          <SidebarMultiItems
            title="تنظیمات"
            menu_icon="bx bx-slider-alt"
            items={[
              {
                title: "خبرنامه",
                isActive: false,
                link: "/letters",
              },
              {
                title: "اعلانات",
                isActive: false,
                link: "/notifications",
              },
              {
                title: "روستا ها",
                isActive: false,
                link: "/villages",
              },
              {
                title: "شهر ها",
                isActive: false,
                link: "/cities",
              },
              {
                title: "ارتباط با ما",
                isActive: false,
                link: "/contacts",
              },
            ]}
          />

          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              style={{ left: "0px", width: "0px" }}
            ></div>
          </div>
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              style={{ left: "0px", width: "0px" }}
            ></div>
          </div>
        </ul>
      </div>
    </div>
  );
};
export default SideBar;
