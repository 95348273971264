import http from "../httpServices";

/**
 *
 * @returns array of cities list
 */
export const getCities = (page) => {
  return http.get(`admin/city?page=${page}`);
};

/**
 *
 * @param {number} city_id id of city which want to fetch details
 * @returns city fetched
 */
export const getCity = (city_id) => {
  return http.get(`admin/city/${city_id}/edit`);
};

/**
 *
 * @param {object} data name & province_id
 * @returns city created
 */
export const createCity = (data) => {
  return http.post("admin/city", data);
};

/**
 *
 * @param {number} city_id id of city which want to update
 * @param {object} data name & province_id
 * @returns city updated
 */
export const updateCity = (city_id, data) => {
  return http.put(`admin/city/${city_id}`, data);
};
