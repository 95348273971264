import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createDifficulty,
  deleteDifficulty,
  getDifficulties,
} from "../../../services/DifficultyServices/difficultyServices";

const initialState = {
  loading: false,
  difficulties: [],
  difficulty_details: {},
};

export const getAllDifficulties = createAsyncThunk(
  "difficulty/getAllDifficulties",
  async () => {
    try {
      const { data } = await getDifficulties();
      console.log(data);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewDifficulty = createAsyncThunk(
  "difficulty/addNewDifficulty",
  async (data) => {
    try {
      const { status } = await createDifficulty(data);
      if (status >= 200) {
        window.location.replace("/");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeDifficulty = createAsyncThunk(
  " difficulty/removeDifficulty",
  async (difficulty_id) => {
    try {
      const { status } = await deleteDifficulty(difficulty_id);
      if (status >= 200) {
        window.location.replace("/");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const difficultySlice = createSlice({
  name: "difficulty",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllDifficulties.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDifficulties.fulfilled, (state, { payload }) => {
        state.difficulties = payload;
        state.loading = false;
      })
      .addCase(addNewDifficulty.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewDifficulty.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default difficultySlice.reducer;
