import PerfectScrollbar from "perfect-scrollbar";
import { useEffect } from "react";

const usePerfectScrollabar = (ref) => {
  useEffect(() => {
    if (ref.current) {
      const ps = new PerfectScrollbar(ref.current, {
        wheelPropagation: true,
      });
      return () => {
        ps.destroy();
      };
    }
  }, [ref]);
};

export default usePerfectScrollabar;
