import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { login, logout } from "../../../services/authServices/authServices";


const initialState = {
  loading: false,
  error: null,
};

export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async (initilaData) => {
    try {
      const { status, data } = await login(initilaData);

      if (status >= 200) {
        Cookies.set("iran_admin_token", data.access_token);
        toast.success("ورود با موفقیت انجام شد");
        setTimeout(() => {
          window.location.replace("/");
        }, 1500);
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const logoutAdmin = createAsyncThunk("auth/logoutAdmin", async () => {
  try {
    Cookies.remove("iran_admin_token")
    const { status } = await logout();
    if (status >= 200) {
      window.location.replace("/login");
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAdmin.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default authSlice.reducer;
