import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ****** Services Methods ****** //
import {
  createType,
  deleteType,
  getType,
  getTypes,
  updateType,
} from "../../../services/TypesServices/typesServices";
// ****** SweetAlert2 ****** //
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { config } from "../../../config/config";

const initialState = {
  loading: false,
  types: [],
  type_detail: {},
};

const Alert = withReactContent(Swal);

export const getAllTypes = createAsyncThunk(
  "types/getAllTypes",
  async ({ page, search }) => {
    try {
      const { data } = await getTypes(page, search);
      return data.types;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getTypeById = createAsyncThunk(
  "types/getTypeById",
  async (type_id) => {
    try {
      const { data } = await getType(type_id);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewType = createAsyncThunk("types/addNewType", async (data) => {
  try {
    const { status } = await createType(data);
    if (status >= 200) {
      window.location.replace("/types");
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const removeType = createAsyncThunk(
  "types/removeType",
  async (type_id) => {
    const { sweet_alert_basic } = config;

    try {
      const { isConfirmed } = await Alert.fire({
        title: "دسته بندی حذف شود ؟",
        ...sweet_alert_basic,
      });

      if (isConfirmed) {
        const { status } = await deleteType(type_id);
        if (status >= 200) {
          window.location.replace("/types");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateTypeById = createAsyncThunk(
  "types/updateTypeById",
  async ({ type_id, data }) => {
    try {
      const { status } = await updateType(type_id, data);
      if (status >= 200) {
        window.location.replace("/types");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const typesSlice = createSlice({
  name: "types",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTypes.fulfilled, (state, { payload }) => {
        state.types = payload;
        state.loading = false;
      })
      .addCase(getTypeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTypeById.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.type_detail = payload;
        state.loading = false;
      })
      .addCase(addNewType.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewType.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTypeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTypeById.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default typesSlice.reducer;
