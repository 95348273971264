import http from "../httpServices";

/**
 *
 * @returns get all sliders
 */
export const getSliders = () => {
  return http.get("admin/slider");
};

/**
 *
 * @param {number} slider_id id of slider which want to get
 * @returns
 */
export const getSlider = (slider_id) => {
  return http.get(`admin/slider/${slider_id}/edit`);
};

/**
 *
 * @param {object} data object of path & url
 * @returns slider created
 */

export const createSlider = (data) => {
  return http.post("admin/slider", data);
};

/**
 *
 * @param {number} slider_id id of slider which want to update
 * @param {object} data object of path & url
 * @returns slider updated
 */
export const updateSlider = (slider_id, data) => {
  return http.post(`admin/slider/${slider_id}?_method=PUT`, data);
};

/**
 *
 * @param {number} slider_id id of slider which want to delete
 * @returns slider deleted
 */

export const deleteSlider = (slider_id) => {
  return http.delete(`admin/slider/${slider_id}`);
};
