export const config = {
  base_url: "https://api.irangardi.app/api/",
  imag_base_url: "https://api.irangardi.app",
  sweet_alert_basic: {
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "بله",
    confirmButtonColor: "green",
    cancelButtonColor: "red",
    cancelButtonText: "خیر",
  },
};
