import { configureStore } from "@reduxjs/toolkit";
import activitySlice from "../features/activitySlice/activitySlice";
import authSlice from "../features/authSlice/authSlice";
import faqcategorySlice from "../features/categories/faqcategorySlice";
import postCategorySlice from "../features/categories/postCategorySlice";
import commentSlice from "../features/comments/commentSlice";
import difficultySlice from "../features/difficultySlice/difficultySlice";
import faqSlice from "../features/faq/faqSlice";
import placeSlice from "../features/placeSlice/placeSlice";
import postSlice from "../features/postSlice/postSlice";
import todosSlice from "../features/todos/todosSlice";
import toolSlice from "../features/tools/toolSlice";
import typesSlice from "../features/types/typesSlice";
import userSlice from "../features/userSlice/userSlice";
import sliderSlice from "../features/sliders/sliderSlice";
import notificationSlice from "../features/notifications/notificationSlice";
import contactSlice from "../features/contactSlice/contactSlice";
import citiesSlice from "../features/citiesSlice/citiesSlice";
import villagesSlice from "../features/villagesSlice/villagesSlice";
import paramSlice from "../features/paramSlice/paramSlice";
import settingSlice from "../features/settingSlice/settingSlice";

export const store = configureStore({
  reducer: {
    postCategory: postCategorySlice,
    faqCategory: faqcategorySlice,
    faqs: faqSlice,
    types: typesSlice,
    tools: toolSlice,
    activity: activitySlice,
    difficulty: difficultySlice,
    todos: todosSlice,
    posts: postSlice,
    places: placeSlice,
    auth: authSlice,
    users: userSlice,
    comments: commentSlice,
    sliders: sliderSlice,
    notifications: notificationSlice,
    contacts: contactSlice,
    city: citiesSlice,
    villages: villagesSlice,
    params: paramSlice,
    settings: settingSlice,
  },
});
